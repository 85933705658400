<template>
  <div class="bg-white">
    <app-modal-customer
      @selectCustomer="selectCustomer"
      :endPoint="endPoint"
      :idEditing="idEditing"
      :screenFlag="screenFlag"
      ref="modal_list_customer">

    </app-modal-customer>

    <app-modal-confirm
      ref="delete_book_modal"
      :modal-header-title="$t('reservation.cancel_book_title')"
      @confirmed="cancelBooking"
    >
      <template v-slot:body>
        <p class="confirm-body">{{ $t("reservation.delete_book_content") }}</p>
      </template>
    </app-modal-confirm>

    <app-modal-confirm
      ref="cancel_book_modal"
      :modal-header-title="$t('reservation.cancel_book_title')"
      @confirmed="cancelBooking"
    >
      <template v-slot:body>
        <p class="confirm-body">{{ $t("reservation.cancel_book_content") }}</p>
      </template>
    </app-modal-confirm>

    <app-modal-confirm
      ref="warning_unique_modal"
      :modal-header-title="$t('reservation.check_unique_name_title')"
      @confirmed="createOrUpdate"
    >
      <template v-slot:body>
        <p class="confirm-body">指定されたプランと時間では既に同姓同名の予約がありますが、このまま登録を行いますか？</p>
      </template>
    </app-modal-confirm>

    <validation-observer
      ref="entry"
      v-slot="{ passes, valid, validated }"
    >

      <form class="form-horizontal" v-on:submit.prevent="submitForm">
        <div class="card-body">
          <div class="form-group row"  v-if="idEditing">
            <label class="col-xl-2 col-lg-3 col-sm-3 col-form-label">{{ $t("reservation.status")}} <span
              class="app-require">＊</span>
            </label>
            <div class="col-xl-5 col-lg-6 col-sm-9 align-self-center">
              <app-radio v-for="(item, index) in meta.status"
                         :isMarginLeft="index !=0 ? true : false" :key="item.id" :label="item.name"
                         :val="item.id" v-model="entry.status_delivered"/>
            </div>
          </div>
          <div class="form-group row" v-if="idEditing">
            <label class="col-xl-2 col-lg-3 col-sm-3 col-form-label">注文番号
            </label>
            <div class="col-xl-5 col-lg-6 col-sm-9 align-self-center">{{ entry.order_number}}
            </div>
          </div>
          <div class="form-group row mb-0">
            <label class="col-xl-1 col-lg-3 col-sm-3 col-form-label">注文日付 <span
              class="app-require">＊</span>
            </label>
            <div class="col-xl-5 col-lg-9 col-sm-9 mb-3">
              <app-date-range-picker
                name="date"
                v-model="entry.date"
                timePicker24Hour="false"
                rules="required"
                format="YYYY-MM-DD"
                @input="entry.date = $event"
                :minDate="minDate"
              />
            </div>
          </div>
          <div class="form-group row mb-0">
            <label class="col-xl-1 col-lg-3 col-sm-3 col-form-label">注文時間 <span
              class="app-require">＊</span>
            </label>
            <div class="col-xl-5 col-lg-9 col-sm-9 mb-3">
              <app-input
                name="hour"
                type="string:5"
                rules="required|hour_minute"
                :class="['flex-fill']"
                v-model="entry.hour"
              />
            </div>
          </div>
          <div class="form-group row" v-if="!idEditing">
            <label class="col-xl-2 col-lg-3 col-sm-3 col-form-label">{{ $t("reservation.mansion") }}
              <span
                class="app-require">＊</span>
            </label>
            <div class="col-xl-5 col-lg-9 col-sm-9">
              <app-select
                name="apartment_id"
                rules="required"
                :options-data="meta.apartments"
                v-model="entry.apartment_id"
                v-if="!idEditing"
              />
              <p @click="handlerApartmentNameClick(entry.apartment_id)" :data-original-title="entry.apartment_name"  v-else  style="color: #007bff;"
                 class="apartment_name app-table-p app-cell-tooltip mb-0 link_name mt-2">
                {{entry.apartment_name}}
              </p>
            </div>
          </div>

          <!-- template menu -->

          <div class="form-group row" v-for="(item, index) in menu_list" v-if="!idEditing && item.available" :key="index" :class="'menu_' + index">
            <label class="col-xl-2 col-lg-3 col-sm-3 col-form-label" v-if="index == 0">メニュー <span class="app-require">＊</span></label>
            <label class="col-xl-2 col-lg-3 col-sm-3 col-form-label" v-else></label>
            <div class="col-xl-4 col-lg-8 col-sm-8">
              <app-select
                name="menu_id"
                rules="required"
                :options-data="meta.menus"
                v-model="item.menu_id"
              />
            </div>
            <div class="col-xl-1 col-lg-3 col-sm-3">
              <app-input
                name="quantity"
                v-model="item.quantity"
                type="number-length:3"
                :rules="VALIDATE.V_TINY_INT_REQUIRED"
              />
            </div>
            <div class="col-xl-1 col-lg-3 col-sm-3">
              <button
                v-if="index > 0"
                style="min-width: 80px !important; height: 40px !important"
                type="button"
                @click="remoteMenuRow(index)"
                class="btn btn-danger"
                :id="'menu_' + index"
              >
                {{ $t("common.delete") }}
              </button>
            </div>
          </div>
          <div class="form-group row" v-if="!idEditing">
            <label class="col-xl-2 col-lg-3 col-sm-3 col-form-label">
            </label>
            <div class="col-xl-4 col-lg-8 col-sm-8">
              <button type="button" class="btn btn-primary" @click="addNewMenu()">メニューを追加</button>
            </div>
          </div>
          <div class="form-group row" v-if="!idEditing">
            <label class="col-xl-2 col-lg-3 col-sm-3 col-form-label">配送料
            </label>
            <div class="col-xl-1 col-lg-6 col-sm-9 align-self-center">
              <app-input
                name="plan_tax"
                type="number-length:11"
                :class="['flex-fill']"
                v-model="entry.ship_fee"
              />
            </div>
          </div>
          <div class="form-group row" v-if="idEditing">
            <label class="col-xl-2 col-lg-3 col-sm-3 col-form-label">メニュー <span class="app-require">＊</span>
            </label>
            <div class="col-xl-4 col-lg-8 col-sm-8">
              <app-select
                name="menu_id"
                rules="required"
                :options-data="meta.menus"
                v-model="entry.menu_id"
              />
            </div>
            <div class="col-xl-1 col-lg-3 col-sm-3">
              <app-input
                name="quantity"
                v-model="entry.num_of_items"
                type="number-length:3"
                :rules="VALIDATE.V_TINY_INT_REQUIRED"
              />
            </div>
          </div>
          <div>
            <div class="form-group row">
              <label class="col-xl-1 col-lg-2 col-sm-2 col-form-label">{{ $t("customer-meals.customer_member_id") }} <span class="app-require">＊</span></label>
              <div class="col-xl-5 col-lg-9 col-sm-9">
                <div class="row">
                  <div class="col-xl-7 col-lg-6 col-md-6 col-sm-6 col-xs-6">
                    <app-input
                      name="customer_member_id"
                      :disabled="true"
                      v-model="entry.customer_id"
                      :class="['flex-fill']"
                      rules="required"
                    />
                  </div>
                  <div class="col-xl-5 col-lg-6 col-md-6 col-sm-6 col-xs-6">
                    <button
                      @click="handleOpenCustomerModal()"
                      class="btn btn-primary w-100"
                      type="button"
                      style="height: 40px !important;"
                    >
                      {{ $t("common.pick_customer") }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-xl-2 col-lg-3 col-sm-3 col-form-label">{{ $t("reservation.name") }}
                <span
                  class="app-require">＊</span>
              </label>
              <div class="col-xl-5 col-lg-9 col-sm-9">
                <app-input
                  name="customer_name"
                  rules="required"
                  type="string:257"
                  :class="['flex-fill']"
                  v-model="entry.customer_name"
                />
              </div>
            </div>
            <div class="form-group row mb-4">
              <label class="col-xl-2 col-lg-3 col-sm-3 col-form-label">{{ $t("reservation.phone") }}
              </label>
              <div class="col-xl-5 col-lg-9 col-sm-9">
                <app-input
                  name="customer_phone"
                  type="phone"
                  rules="phone_number"
                  :class="['flex-fill']"
                  v-model="entry.customer_phone"
                  placeholder="00000000000"
                />
              </div>
            </div>
          </div>
          <div class="form-group row" v-if="idEditing">
            <label class="col-xl-2 col-lg-3 col-sm-3 col-form-label">配送料
            </label>
            <div class="col-xl-5 col-lg-6 col-sm-9 align-self-center">{{ convertFormatMoney(entry.ship_fee) }}
            </div>
          </div>
          <div class="form-group row" v-if="idEditing">
            <label class="col-xl-2 col-lg-3 col-sm-3 col-form-label">合計金額
            </label>
            <div class="col-xl-5 col-lg-6 col-sm-9 align-self-center">{{ convertFormatMoney(entry.amount) }}
            </div>
          </div>

          <div class="form-group row">
            <label class="col-xl-2 col-lg-3 col-sm-3 col-form-label">メモ
            </label>
            <div class="col-xl-5 col-lg-9 col-sm-9">
              <app-text
                name="note"
                type="string1024"
                :class="['flex-fill']"
                v-model="entry.note"
              />
            </div>
          </div>
          <div class="card-footer row justify-content-end gr-button">
            <button type="button" :disabled="!valid && validated" class="btn btn-default m-r-10"
                    @click="handlerCancelButton">
              {{ idEditing ?  $t('common.cancel')  :  $t('common.cancel') }}
            </button>
            <button class="btn btn-primary" :disabled="!valid && validated" type="submit">
              {{idEditing ? $t('common.save') : $t('common.create')}}
            </button>
          </div>
        </div>
      </form>
    </validation-observer>
  </div>
</template>

<script>

import {FACILITIES_CONSTANT} from "../../../constants/facilities";
import {RESERVATION_CONSTANT} from "../../../constants/reservation";
import {ENDPOINT} from "@constants/api";
import {ROUTES} from "@constants";
import _ from "lodash";


export default {
  name: "ReservationCreateOrEdit",

  data() {
    const id = this.$route.params.id
    return {
      meta: {
        apartments: [],
        menus: [],
        status: [
          {id: 0, name: "注文済"},
          {id: 1, name: "受渡済"},
          {id: 2, name: "キャンセル"},
        ]
      },
      entry: {
        apartment_id: this.$route.query.apartment_id ? this.$route.query.apartment_id : "",
        customer_id: "",
        customer_phone: "",
        status_booking: 0,
        date : this.$route.query.date ? this.$route.query.date : moment().format('YYYY-MM-DD'),
        hour : moment().format('HH:mm'),
        menus: [],

      },
      menu_list: [
        {
          quantity: 1,
          available : true
        },
      ],
      minDate : moment().format('YYYY-MM-DD'),
      idEditing: id,
      endPoint: ENDPOINT.CUSTOMER_LIST + "?filters[status_enabled][equal]=1&filters[apartment_id][equal]=",
      screenFlag: 3,
      firstTimeComponentLoad : true,
    }
  },
  watch: {
    "entry.apartment_id": async function (value) {
      this.$request.get(this.ENDPOINT.UI_HELPER_ORDER_LIST + "?apartment_id=" + value).then(res => {
        this.meta.menus = res.data.menus;
        this.meta.menus.unshift({
          id: "",
          name: "--選択--"
        })
      })
      if (value && this.loading) {
        this.endPoint = ENDPOINT.CUSTOMER_LIST + "?filters[status_enabled][equal]=1&filters[apartment_id][equal]=" + value
        await this.updateChangedValue({apartment_id: value, facility_id : this.entry.facility_id,
          plan_id : this.entry.plan_id, date : this.entry.date, status_booking: this.entry.real_status_booking})
      } else {
        this.endPoint = ENDPOINT.CUSTOMER_LIST + "?filters[status_enabled][equal]=1&filters[apartment_id][equal]="
      }
      if (!this.idEditing) {
        if (this.entry.customer_id) {
          this.entry.customer_phone = '';
          this.entry.customer_name = '';
        }
        this.entry.customer_id = '';
      }
    }
  },
  async created() {
    if (this.idEditing) {
      this.loading = false
      await this.$request.get(this.ENDPOINT.ORDER_LIST_EDIT(this.idEditing)).then(res => {
        if (res.hasErrors()) {
          if (res.status === 'error'){
            this.__noticeError(this.$t(res.data.error_msg));
          }
          this.redirectDefaultValue()
        } else {
          this.entry = res.data.data
        }
      })
    }
  },

  mounted: async function () {
    this.$request.get(this.ENDPOINT.UI_HELPER_ORDER_LIST).then(res => {
      this.meta.apartments = res.data.apartments,
      this.meta.apartments.unshift({
        id: "",
        name: "--選択--"
      })
      this.meta.menus.unshift({
        id: "",
        name: "--選択--"
      })
    })

  },
  methods: {
    addNewMenu() {
      this.menu_list.push({
        quantity: 1,
        available: true,
      });
    },
    remoteMenuRow(key) {
      this.menu_list[key].available = false
    },
    updateChangedValue(params) {
      this.$request.get(this.ENDPOINT.UI_HELPER_RESERVATION, params).then(res => {
        this.meta = {
          apartments: res.data.apartments,
        }
        this.meta.apartments.unshift({
          id: "",
          name: "--選択--"
        })
        this.$forceUpdate()
      })
    },
    handleOpenCustomerModal() {
      this.endPoint = ENDPOINT.CUSTOMER_LIST + "?filters[status_enabled][equal]=1&filters[apartment_id][equal]=" + this.entry.apartment_id
      this.$refs.modal_list_customer.show();
    },
    handleOpenCancelBookModal() {
        this.$refs.delete_book_modal.show();
    },
    selectCustomer(data) {
      this.entry.customer_id = data.row.id
      this.entry.customer_phone = data.row.phone_number
      this.entry.customer_name = data.row.name
      this.$refs.modal_list_customer.hide();
    },
    async submitForm() {
      if (!await this.$refs.entry.validate()) {
        return;
      }
      await this.createOrUpdate()
    },
    async createOrUpdate() {
      if (!await this.$refs.entry.validate()) {
        return;
      }
      let res = null;
      let msg = "";

      if (this.idEditing) {
        res = await this.$request.patch(this.ENDPOINT.ORDER_EDIT(this.idEditing), this.entry);
        msg = this.$t("common.msg update ok")
      } else {
        let params = this.menu_list.filter(function (el) {
          return el.available === true
        });
        this.entry.menus = params
        res = await this.$request.post(this.ENDPOINT.ORDER_CREATE, this.entry);
        msg = this.$t("common.msg create ok")
      }
      if (res.hasErrors()) {
        this.__noticeError(res.data.error_msg != undefined ? res.data.error_msg : this.$t('common.error'));
        if (res.status === 'error') {
          this.redirectDefaultValue()
        }
      } else {
        this.__noticeSuccess(msg);
        this.redirectDefaultValue();
      }
    },
    async cancelBooking() {
      const res = await this.$request.post(this.ENDPOINT.BOOK_RESERVATION_CANCEL(this.idEditing));
      if (!res.hasErrors()) {
        this.__noticeSuccess(this.$t("common.msg delete ok"));
        this.redirectDefaultValue();
      } else {
        this.__noticeError(res.data.error_msg != undefined ? res.data.error_msg : this.$t('common.error'));
        this.$refs.delete_book_modal.hide();
        this.$refs.cancel_book_modal.hide();
        if (res.status === 'error') {
          this.redirectDefaultValue()
        }
      }
    },
    convertFormatMoney(amount) {
      if (!amount) return '￥' + '0';
      return '￥' + amount.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',');
    },
    handlerCancelButton() {
      if(this.$store.state.setting.prevRouter.name === ROUTES.ADMIN.ORDER_LIST) {
        this.__redirectBack()
      } else {
        this.redirectDefaultValue()
      }
    },
    handlerApartmentNameClick(apartment_id) {
      let routeApartment = this.$router.resolve({name: this.ROUTES.ADMIN.APARTMENT_EDIT, params: {id: apartment_id}});
      window.open(routeApartment.href, '_blank');
    },
    redirectDefaultValue() {
      this.$router.push({
        name: this.ROUTES.ADMIN.ORDER_LIST,
        query: {
          'filters.status_delivered.equal' : 'all',
        }
      });
    }
  }
}
</script>

<style>
@media (min-width: 768px) and (max-width: 1400px) {
  .icon_nga {
    width: 100%;
  }
  .num-people {
    padding-top: 20px;
  }
}
.link_name:hover {
  cursor: pointer;
  color: darkblue !important;
}
.daterangepicker.show-calendar td.disabled,
.daterangepicker.show-calendar option.disabled{
  text-decoration: inherit;
}
</style>
